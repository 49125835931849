import React from 'react';

function App() {
  return (
    <div>
      <h1>Page Not Found</h1>
      <p>The link you followed may be broken or the page may have been removed.</p>
      {/* Optionally, provide a link back to the home page or other navigation */}
      <a href="/">Go to Home Page</a>
    </div>
  );
}

export default App;
